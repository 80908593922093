<template>
  <div class="g1">
    <div class="container">
      <div class="c-up">
        <div class="container-information">
          <p class="text-front" style="font-family: Poppins;">Genera más oportunidades para estudiantes y profesionales
          </p>
        </div>
      </div>
      <div class="J-tab-container">
        <div class="J-tab-header">
          <div class="J-tab-nav">
            <div class="J-tab-nav-item" v-for="(tab, i) in tabs" :key="i + 1" :class="{ active: tab.checked }">
              <a href="javascript:void(0)" @click="activeTabs(i)" class="J-tab-nav-link">{{ i + 1 }}</a>
              <div class="J-tab-nav-text">
                <p>{{ tab.title }}</p>
              </div>
            </div>
          </div>
        </div>
        <div class="J-tab-body">
          <!-- Datos Representante Empresarial -->
          <div v-show="tabs[0].active">
            <form @submit.prevent="activeTabs(1)" class="row J-flex center">
              <!--Nombre Representante Empresa-->
              <div class="col-md-6">
                <div class="form-floating mb-3">
                  <input type="text" v-model="state.responsableCompany" class="form-control" id="nameComercialInput"
                    required placeholder="Nombre Completo del Contacto">
                  <label for="floatingInput">Nombre Completo del Contacto</label>
                </div>
              </div>
              <!--Telefono Contacto Empresa-->
              <div class="col-md-6">
                <div class="form-floating mb-3">
                  <input type="tel" v-model="state.phoneCompany" class="form-control" id="nameComercialInput" required
                    @keypress="onlyNumber" placeholder="Télefono de Contacto">
                  <label for="floatingInput">Télefono de Contacto</label>
                </div>
              </div>
              <!--Posición Representante -->
              <div class="col-md-6">
                <div class="form-floating mb-3">
                  <input type="text" v-model="state.positionCompany" class="form-control" id="nameComercialInput"
                    required placeholder="Posición dentro de la empresa">
                  <label for="floatingInput">Posición dentro de la empresa</label>
                </div>
              </div>
              <!--Correo Empresarial-->
              <div class="col-md-6">
                <div class="form-floating mb-3">
                  <input type="email" v-model="state.emailCompany" class="form-control" id="nameComercialInput" required
                    placeholder="Correo empresarial de contacto">
                  <label for="floatingInput">Correo empresarial de contacto</label>
                </div>

              </div>
              <!--Tenemos state.emailCompany y statecompany (diferencia no se cual sea hasta el momento)-->
              <br />
              <!--Botones siguiente y atras-->
              <div class="J-flex between mt-5">
                <div class="col-4 J-flex start">
                  <button class="J-btn J-btn-line" type="button" @click="activeTabs(0)">
                    Atras
                  </button>
                </div>
                <!--Boton siguiente funciona hasta la validación de los campos-->
                <div class="col-4 J-flex end">
                  <button class="J-btn J-btn-secondary">Siguiente</button>
                </div>
              </div>
            </form>
          </div>
          <!--Datos Acerca de la empresa-->
          <div v-show="tabs[1].active">
            <form class="row J-flex center" @submit.prevent="activeTabs(2)">
              <!--Nombre Comercial de la empresa-->
              <div class="col-md-6">
                <div class="form-floating mb-3">
                  <input type="text" v-model="state.nameCompany" class="form-control" id="nameComercialInput" required
                    placeholder="Nombre Comercial">
                  <label for="floatingInput">Nombre Comercial</label>
                </div>
              </div>
              <!--Télefono de contacto-->
              <div class="col-md-6">
                <div class="form-floating mb-3">
                  <input type="text" v-model="state.razonSocialCompany" class="form-control" id="razonSociallInput"
                    required placeholder="Razón Social">
                  <label for="floatingInput">Razón Social</label>
                </div>
              </div>
              <!--Sitio Web o LinkedIn-->
              <div class="col-md-6">
                <div class="form-floating mb-3">
                  <input type="text" v-model="state.sitioWeb" class="form-control" id="urlInput" required
                    placeholder="Razón Social">
                  <label for="floatingInput">URL de Sitio Web</label>
                </div>
              </div>
              <!--Entidad Federativa-->
              <div class="col-md-6">
                <div class="form-floating mb-3">
                  <select class="form-select" id="floatingSelect" aria-label="Estados"
                    v-model="state.federalentityCompany" required>
                    <option value="" selected disabled>
                      Selecciona una opción
                    </option>
                    <option value="Aguascalientes">Aguascalientes</option>
                    <option value="Baja California">Baja California</option>
                    <option value="Baja California Sur">Baja California Sur</option>
                    <option value="Campeche">Campeche</option>
                    <option value="Chiapas">Chiapas</option>
                    <option value="Chihuahua">Chihuahua</option>
                    <option value="CDMX">Ciudad de México</option>
                    <option value="Coahuila">Coahuila</option>
                    <option value="Colima">Colima</option>
                    <option value="Durango">Durango</option>
                    <option value="Estado de México">Estado de México</option>
                    <option value="Guanajuato">Guanajuato</option>
                    <option value="Guerrero">Guerrero</option>
                    <option value="Hidalgo">Hidalgo</option>
                    <option value="Jalisco">Jalisco</option>
                    <option value="Michoacán">Michoacán</option>
                    <option value="Morelos">Morelos</option>
                    <option value="Nayarit">Nayarit</option>
                    <option value="Nuevo León">Nuevo León</option>
                    <option value="Oaxaca">Oaxaca</option>
                    <option value="Puebla">Puebla</option>
                    <option value="Querétaro">Querétaro</option>
                    <option value="Quintana Roo">Quintana Roo</option>
                    <option value="San Luis Potosí">San Luis Potosí</option>
                    <option value="Sinaloa">Sinaloa</option>
                    <option value="Sonora">Sonora</option>
                    <option value="Tabasco">Tabasco</option>
                    <option value="Tamaulipas">Tamaulipas</option>
                    <option value="Tlaxcala">Tlaxcala</option>
                    <option value="Veracruz">Veracruz</option>
                    <option value="Yucatán">Yucatán</option>
                    <option value="Zacatecas">Zacatecas</option>
                  </select>
                  <label for="floatingSelect">Entidad Federativa</label>
                </div>
              </div>
              <!--Botones Atras y Siguiente funciona al validar los campos llenos-->
              <div class="J-flex between mt-5">
                <div class="col-4 J-flex start">
                  <button class="J-btn J-btn-line" type="button" @click="activeTabs(0)">
                    Atras
                  </button>
                </div>
                <div class="col-4 J-flex end">
                  <button class="J-btn J-btn-secondary">Siguiente</button>
                </div>
              </div>
            </form>
          </div>
          <!--Número de empleados-->
          <div v-show="tabs[2].active">
            <form class="row J-flex center" @submit.prevent="activeTabs(3)">
              <!--Radio buttons número de empleados-->
              <div class="col"
                style="display:flex; justify-content:center; align-content: center; flex-direction: column;">
                <div class="container-text-note-a" style="display:flex; justify-content:center; align-content: center;">
                  <p class="text-note-a">Ingresa el número de empleados</p>
                </div>
                <div style="display:flex; justify-content:center; align-content: center; flex-direction: row;">
                  <div class="form-check">
                    <input class="form-check-input" type="radio" name="gridRadios" id="gridRadios1"
                      v-model="state.employeeNumber" value="1-10" checked />
                    <label class="form-check-label" for="gridRadios1">
                      1 - 10
                    </label>
                  </div>
                  <!--NO TIENE INPUT O VALIDATION DENTRO DE LOS METODOS, SOLO INPUT CLASS-->
                  &nbsp; &nbsp; &nbsp;
                  <div class="form-check">
                    <input class="form-check-input" type="radio" name="gridRadios" id="gridRadios2"
                      v-model="state.employeeNumber" value="10-100" />
                    <label class="form-check-label" for="gridRadios2">
                      10 - 100
                    </label>
                  </div>
                  &nbsp; &nbsp; &nbsp;
                  <div class="form-check">
                    <input class="form-check-input" type="radio" name="gridRadios" id="gridRadios3"
                      v-model="state.employeeNumber" value="100-500" />
                    <label class="form-check-label" for="gridRadios3">
                      100 - 500
                    </label>
                  </div>
                  &nbsp; &nbsp; &nbsp;
                  <div class="form-check">
                    <input class="form-check-input" type="radio" name="gridRadios" id="gridRadios4"
                      v-model="state.employeeNumber" value="+500" />
                    <label class="form-check-label" for="gridRadios4">
                      +500
                    </label>
                  </div>
                </div>
              </div>
              <!--Selección de sector empresarial-->
              <div class="col-md-6" style="display:flex; justify-content: center;">
                <div class="form-floating">
                  <!--V-MODEL ES CORRECTO-->
                  <select class="form-select" id="floatingSelect" aria-label="Floating label select example"
                    v-model="state.businessSector" required>
                    <option value="" selected disabled>
                      Selecciona una opción
                    </option>
                    <option v-for="(item, i) in dataSectores" :key="i + 1" :value="item.name"> {{ item.name }}
                    </option>
                  </select>
                  <label for="floatingSelect">Sector Empresarial</label>
                </div>
              </div>
              <!--Botones atras y siguiente (Misma funcionalidad)-->
              <div class="J-flex between mt-5">
                <div class="col-4 J-flex start">
                  <button class="J-btn J-btn-line" type="button" @click="activeTabs(1)">
                    Atras
                  </button>
                </div>
                <div class="col-4 J-flex end">
                  <button class="J-btn J-btn-secondary">Siguiente</button>
                </div>
              </div>
            </form>
          </div>
          <!--Documentos Empresa Validación-->
          <div v-show="tabs[3].active">
            <form class="row J-flex center" @submit.prevent="submitFormB()">
              <!--Casilla para subir documemnto-->
              <div class="new" style="display:flex; justify-content: center; align-items: center; margin-left: 20px;">
                <div class="J-flex" style="width: 100%; display:flex; flex-direction: column;">
                  <div class="col-md-4" style="width: 90%">
                    <UploadPartials title="Constancia de Situación Físcal" @data="changeFileCf" />
                  </div>
                  <div style="width: 90%; display:flex; justify-content: center; align-items: center;">
                    <p style="color: #1b4f99; font-weight: bold">Constancia no mayor a 6 meses</p>
                  </div>
                </div>
                <!-- <div class="J-flex" style="width: 100%; display:flex; flex-direction: column;">
                  <div class="col-md-4" style="width: 90%">
                    <UploadPartials title="Brochure Empresarial" @data="changeFile" />
                  </div>
                  <div style="width: 90%; display:flex; justify-content: center; align-items: center;">
                    <p style="color: #1b4f99; font-weight: bold">Información de la empresa</p>
                  </div>
                </div> -->
              </div>
              <div class="col-md-12 J-flex center mt-3">
                <div class="form-check form-switch">
                  <input class="form-check-input" v-model="state.terms" type="checkbox" role="switch" id="terms"
                    required />
                  <a href="https://api.mundouniversitario.mx/uploads/files/Aviso_Privacidad.pdf"
                    class="form-check-label" for="terms" target="_blank" style="color: black;">AVISO DE PRIVACIDAD</a>
                </div>
              </div>
              <div class="col-md-12 J-flex center mt-3">
                <div class="form-check form-switch">
                  <input class="form-check-input" v-model="state.useAcept" type="checkbox" role="switch" id="terms"
                    required />
                  <a href="https://api.mundouniversitario.mx/uploads/files/Acuerdo_Usuario.pdf" class="form-check-label"
                    for="terms" target="_blank" style="color: black;">ACUERDO DE USO</a>
                </div>
              </div>
              <!--Misma función de botones atras-->
              <div class="J-flex between mt-5">
                <div class="col-4 J-flex start">
                  <button class="J-btn J-btn-line" type="button" @click="activeTabs(4)">
                    Atras
                  </button>
                </div>
                <!--Boton Enviar falta cambiar el Back-->
                <div class="col-4 J-flex end">
                  <button type="submit" :disabled="response.loading" class="J-btn J-btn-secondary"> Enviar
                    <div class="spinner-border text-primary" role="status" v-if="response.loading">
                      <span class="sr-only">Loading...</span>
                    </div>
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, reactive } from "vue";
import useValidate from "@vuelidate/core";
import UploadPartials from "@/components/partials/upload.partials.vue";
import { required, minLength, maxLength, helpers } from "@vuelidate/validators";
import { CompanyService } from "@/services";

export default {
  components: {

    UploadPartials,
  },
  setup() { //en orden de aparición
    const state = reactive({
      responsableCompany: "",
      phoneCompany: "",
      emailCompany: "",
      positionCompany: "",
      nameCompany: "",
      razonSocialCompany: "",
      sitioWeb: "",
      federalentityCompany: "",
      employeeNumber: "",
      filecf: "",
      businessSector: "",
      terms: false,
      useAcept: false,
    });
    const rules = computed(() => {
      return {
        companyname: {
          required: helpers.withMessage("Por favor, llene el campo.", required),
        },
        emailcompany: {
          required: helpers.withMessage("Por favor, llene el campo.", required),
        },
        phone: {
          required: helpers.withMessage("Por favor, llene el campo.", required),
          minLength: helpers.withMessage(
            "El número debe de tener mínimo 10 dígitos.",
            minLength(10)
          ),
          maxLength: helpers.withMessage(
            "El número debe tener máximo 12 dígitos.",
            maxLength(12)
          ),
        },
        nacionality: {
          required: helpers.withMessage("Por favor, llene el campo.", required),
        },
      };
    });
    const v$ = useValidate(rules, state);
    return {
      state,
      v$,
    };
  },
  data() {
    return {
      file: "",
      filecf: "",
      tabs: [
        {
          title: "Datos de acceso y validación",
          active: true,
          checked: true,
        },
        {
          title: "Acerca de la empresa",
          active: false,
          checked: false,
        },
        {
          title: "Número de empleados",
          active: false,
          checked: false,
        },
        {
          title: "Documentos",
          active: false,
          checked: false,
        },
      ],
      tempSkill: "",
      skills: [],
      dataSectores: [
        {
          "name": "Administración gubernamental"
        },
        {
          "name": "Administraciones públicas"
        },
        {
          "name": "Aeronáutica/Aviación"
        },
        {
          "name": "Agricultura"
        },
        {
          "name": "Alimentación y bebidas"
        },
        {
          "name": "Almacenamiento"
        },
        {
          "name": "Animación"
        },
        {
          "name": "Apuestas y casinos"
        },
        {
          "name": "Arquitectura y planificación"
        },
        {
          "name": "Artes escénicas"
        },
        {
          "name": "Artesanía"
        },
        {
          "name": "Artículos de consumo"
        },
        {
          "name": "Artículos de lujo y joyas"
        },
        {
          "name": "Artículos deportivos"
        },
        {
          "name": "Asuntos internacionales"
        },
        {
          "name": "Atención a la salud mental"
        },
        {
          "name": "Atención sanitaria y hospitalaria"
        },
        {
          "name": "Automatización industrial"
        },
        {
          "name": "Banca"
        },
        {
          "name": "Banca de inversiones"
        },
        {
          "name": "Bellas Artes"
        },
        {
          "name": "Bibliotecas"
        },
        {
          "name": "Bienes inmobiliarios"
        },
        {
          "name": "Bienes inmobiliarios comerciales"
        },
        {
          "name": "Biotecnología"
        },
        {
          "name": "Capital de riesgo y capital privado"
        },
        {
          "name": "Comercio"
        },
        {
          "name": "Construcción"
        },
        {
          "name": "Construcción naval"
        },
        {
          "name": "Consultoría"
        },
        {
          "name": "Consultoría de estrategia y operaciones"
        },
        {
          "name": "Contabilidad"
        },
        {
          "name": "Cosmética"
        },
        {
          "name": "Cristal, cerámica y hormigón"
        },
        {
          "name": "Cumplimiento de la ley"
        },
        {
          "name": "Departamento de defensa y del espacio exterior"
        },
        {
          "name": "Deportes"
        },
        {
          "name": "Derecho"
        },
        {
          "name": "Desarrollo de programación"
        },
        {
          "name": "Desarrollo y comercio internacional"
        },
        {
          "name": "Diseño"
        },
        {
          "name": "Diseño gráfico"
        },
        {
          "name": "Distribución"
        },
        {
          "name": "Dotación y selección de personal"
        },
        {
          "name": "Educación"
        },
        {
          "name": "Elaboración de metal y fundición"
        },
        {
          "name": "E-learning"
        },
        {
          "name": "Electrónica de consumo"
        },
        {
          "name": "Embalaje y contenedores"
        },
        {
          "name": "Energía renovable y medio ambiente"
        },
        {
          "name": "Enseñanza superior"
        },
        {
          "name": "Entretenimiento"
        },
        {
          "name": "Envío de paquetes y carga"
        },
        {
          "name": "Equipos informáticos"
        },
        {
          "name": "Escritura y edición"
        },
        {
          "name": "Explotación de los recursos forestales"
        },
        {
          "name": "Filantropía"
        },
        {
          "name": "Formación profesional y capacitación"
        },
        {
          "name": "Fotografía"
        },
        {
          "name": "Gabinetes estratégicos"
        },
        {
          "name": "Ganadería"
        },
        {
          "name": "Gestión de inversiones"
        },
        {
          "name": "Gestión de organizaciones sin ánimo de lucro"
        },
        {
          "name": "Gestión educativa"
        },
        {
          "name": "Hostelería"
        },
        {
          "name": "Importación y exportación"
        },
        {
          "name": "Imprenta"
        },
        {
          "name": "Industria aeroespacial y aviación"
        },
        {
          "name": "Industria farmacéutica"
        },
        {
          "name": "Industria química"
        },
        {
          "name": "Industria textil y moda"
        },
        {
          "name": "Ingeniería civil"
        },
        {
          "name": "Ingeniería industrial o mecánica"
        },
        {
          "name": "Instalaciones y servicios recreativos"
        },
        {
          "name": "Interconexión en red"
        },
        {
          "name": "Internet"
        },
        {
          "name": "Investigación"
        },
        {
          "name": "Investigación científica"
        },
        {
          "name": "Investigación de mercado"
        },
        {
          "name": "Judicial"
        },
        {
          "name": "Lácteos"
        },
        {
          "name": "Logística y cadena de suministro"
        },
        {
          "name": "Manufactura eléctrica/electrónica"
        },
        {
          "name": "Manufactura ferroviaria"
        },
        {
          "name": "Maquinaria"
        },
        {
          "name": "Marketing y publicidad"
        },
        {
          "name": "Material y equipo de negocios"
        },
        {
          "name": "Materiales de construcción"
        },
        {
          "name": "Medicina"
        },
        {
          "name": "Medicina alternativa"
        },
        {
          "name": "Medios de comunicación en línea"
        },
        {
          "name": "Medios de difusión"
        },
        {
          "name": "Mercados de capital"
        },
        {
          "name": "Minería y metalurgia"
        },
        {
          "name": "Mobiliario"
        },
        {
          "name": "Museos e instituciones"
        },
        {
          "name": "Música"
        },
        {
          "name": "Nanotecnología"
        },
        {
          "name": "Ocio, viajes y turismo"
        },
        {
          "name": "Oficina ejecutiva"
        },
        {
          "name": "Oficina legislativa"
        },
        {
          "name": "Organización cívica y social"
        },
        {
          "name": "Organización política"
        },
        {
          "name": "Películas y cine"
        },
        {
          "name": "Periódicos"
        },
        {
          "name": "Petróleo y energía"
        },
        {
          "name": "Piscicultura"
        },
        {
          "name": "Plásticos"
        },
        {
          "name": "Política pública"
        },
        {
          "name": "Producción alimentaria"
        },
        {
          "name": "Producción multimedia"
        },
        {
          "name": "Productos de papel y forestales"
        },
        {
          "name": "Productos químicos"
        },
        {
          "name": "Profesiones médicas"
        },
        {
          "name": "Protección civil"
        },
        {
          "name": "Publicaciones"
        },
        {
          "name": "Recaudación de fondos"
        },
        {
          "name": "Recursos humanos"
        },
        {
          "name": "Relaciones gubernamentales"
        },
        {
          "name": "Relaciones públicas y comunicaciones"
        },
        {
          "name": "Resolución de conflictos por terceras partes"
        },
        {
          "name": "Restaurantes"
        },
        {
          "name": "Salud"
        },
        {
          "name": "Sanidad, bienestar y ejercicio"
        },
        {
          "name": "Sector automovilístico"
        },
        {
          "name": "Sector textil"
        },
        {
          "name": "Seguridad del ordenador y de las redes"
        },
        {
          "name": "Seguridad e investigaciones"
        },
        {
          "name": "Seguros"
        },
        {
          "name": "Servicio al consumidor"
        },
        {
          "name": "Servicio de información"
        },
        {
          "name": "Servicios de eventos"
        },
        {
          "name": "Servicios de reparación y mantenimiento"
        },
        {
          "name": "Servicios financieros"
        },
        {
          "name": "Servicios financieros y de seguros"
        },
        {
          "name": "Servicios infraestructurales"
        },
        {
          "name": "Servicios jurídicos"
        },
        {
          "name": "Servicios médicos"
        },
        {
          "name": "Servicios medioambientales"
        },
        {
          "name": "Servicios para el individuo y la familia"
        },
        {
          "name": "Servicios públicos"
        },
        {
          "name": "Servicios y tecnologías de la información"
        },
        {
          "name": "Software"
        },
        {
          "name": "Subcontrataciones/Offshoring"
        },
        {
          "name": "Supermercados"
        },
        {
          "name": "Tecnología inalámbrica"
        },
        {
          "name": "Telecomunicaciones"
        },
        {
          "name": "Traducción y localización"
        },
        {
          "name": "Transporte por carretera o ferrocarril"
        },
        {
          "name": "Transporte y alojamiento de bienes"
        },
        {
          "name": "Venta al por mayor"
        },
        {
          "name": "Venta al por menor"
        },
        {
          "name": "Veterinaria"
        },
        {
          "name": "Videojuegos"
        },
        {
          "name": "Vinos y licores"
        },
      ],
      response: {
        error: false,
        loading: false,
        success: false,
        message: "",
      },
    };
  },
  methods: {
    changeFile(data) {
      this.state.file = data;
    },
    changeFileCf(data) {
      this.state.filecf = data;
    },
    activeTabs(i) {
      this.tabs.forEach((el, index) => {
        if (i === index) {
          el.active = true;
          el.checked = true;
        } else {
          el.active = false;
        }
      });
    },
    async saveData() {
      try {
        this.response.loading = true;
        const resp = await CompanyService.createCompany(this.state);
        const { data } = resp.data;
        localStorage.setItem("accessToken", resp.data.accessToken);
        localStorage.setItem("user", JSON.stringify(resp.data.data.user));
        window.location.href = "/empresa/perfil";
        this.response.loading = false;
      } catch (e) {
        this.response.loading = false;
        this.$swal({
          position: "top-center",
          icon: "error",
          text: e.resp,
          showConfirmButton: false,
          timer: 2000,
        });
      }
    },
    submitFormB() {
      if (this.state.filecf.length === 0) {
        let title = "Sube tu Constancia de Situación Fiscal";
        this.alertUse("center", title, "warning");
      }
      else {
        this.saveData();
      }
    },
    alertUse(positionAlert, title, iconalert) {
      this.$swal({
        position: positionAlert,
        icon: iconalert,
        timer: 5000,
        html: "<center>" + title + "</center>",
        confirmButtonText: 'Continuar <i class="fa fa-thumbs-up"></i> ',
        confirmButtonAriaLabel: "Thumbs up, great!",
      });
    },
    onlyNumber($event) {
      let keyCode = $event.keyCode ? $event.keyCode : $event.which;
      if ((keyCode < 48 || keyCode > 57) && keyCode !== 44) {
        // 46 is dot
        $event.preventDefault();
      }
    },
  },
};
</script>

<style land="scss" scoped>
@import "../styles/formularioempresa.scss";
</style>
